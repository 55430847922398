<template>
    <div class="card">
        <h5 class="mb-5 pb-3">Detail Transaksi</h5>

        <!-- <h6 class="text-black roboto-medium">Biodata</h6>
        <hr> -->
        <div class="overflow-auto mb-5">
            <table class="table">
                <tbody>
                    <tr>
                        <td>Transaksi ID</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.trx_id }}
                        </td>
                    </tr>
                    <tr>
                        <td>Transaksi</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.title }}
                        </td>
                    </tr>
                    <tr>
                        <td>Tipe Transaksi</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.type }}
                        </td>
                    </tr>
                    <tr>
                        <td>Kategori Transaksi</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.category }}
                        </td>
                    </tr>
                    <tr>
                        <td>Tanggal Transaksi</td>
                        <td v-if="loading"><Skeleton width="150px" /></td>
                        <td v-else>
                            {{ moment(data.trx_date).format('DD MMM YYYY HH:mm') }} WIB
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            <Tag class="mr-2 py-0 text-10 bg-linear-secondary border-round-lg" value="Pending" v-if="data.status === 0"></Tag>
                            <Tag class="mr-2 py-0 text-10 border-round-lg bg-linear-green" value="Success" v-else-if="data.status === 1"></Tag>
                            <Tag class="mr-2 py-0 text-10 border-round-lg bg-linear-red" value="Cancel" v-else-if="data.status === 2"></Tag>
                        </td>
                    </tr>
                    <tr>
                        <td>Nama Customer</td>
                        <td v-if="loading"><Skeleton width="100px" /></td>
                        <td v-else>
                            {{ data.cust_name }}
                        </td>
                    </tr>
                    <tr>
                        <td>Amount</td>
                        <td v-if="loading"><Skeleton width="150px" /></td>
                        <td v-else>
                            {{ formatCurency(data.detail ? data.detail.amount : 0) }}
                        </td>
                    </tr>
                    <tr>
                        <td>Fee</td>
                        <td v-if="loading"><Skeleton width="150px" /></td>
                        <td v-else>
                            {{ formatCurency(data.detail ? data.detail.fee : 0) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <h6 class="text-black roboto-medium">Detail</h6>
        <hr>
        <div class="overflow-auto mb-5">
            <table class="table">
                <tbody>
                    <template v-if="data.category === 'PURCHASE_CREDIT' || data.category === 'PURCHASE_DATA'">
                        <tr>
                            <td>Serial Number</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.serial_number : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Bill</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.bill_id : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Produk</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.product_name : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Deskripsi</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.description : '' }}
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td>Account Name</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.account_name : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Akun Bank</td>
                            <td v-if="loading"><Skeleton width="100px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.bank_account : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank</td>
                            <td v-if="loading"><Skeleton width="150px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.bank_name : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>No. Referensi</td>
                            <td v-if="loading"><Skeleton width="150px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.ref_no : '' }}
                            </td>
                        </tr>
                        <tr>
                            <td>Note</td>
                            <td v-if="loading"><Skeleton width="150px" /></td>
                            <td v-else>
                                {{ data.detail ? data.detail.note : '' }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'transaction-detail',
    props: ['id'],
    data() {
        return {
            moment: require('moment-timezone'),
            loading: false,
            data: {},
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/transaction/${this.id}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data
                })
        },
        formatCurency(data) {
            let value = parseInt(data)
            return value.toLocaleString('id-ID', {style: 'currency', currency: 'IDR', minimumFractionDigits: 0})
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';

.table {
    td {
        color: $black;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    tr td:first-child{
        width: 200px;
        opacity: .6;
        @media (max-width: 575px) {
            width: 45%;
        }
    }
}
</style>
